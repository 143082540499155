// Modules
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Components
import Header from "../components/header/HeaderContainer";
import Footer from "../components/footer/Footer";

const ErrorPage = ({ isAnnouncement, isError = false }) => {
  return (
    <>
      <Container
        fluid
        className={`${
          isAnnouncement
            ? "navbar_announcement_vertical_spacing"
            : "navbar_vertical_spacing"
        }`}
      >
        <Header />
        <Container>
          <Row className="text-center light_black_text">
            <Col>
              <div
                className={"section_heading center"}
                style={{ marginBottom: "35vh", marginTop: "17vh" }}
              >
                <h1 className="heading_text primary_orange_text">
                  Oops!
                  <br />
                </h1>
                <p className="paragraph_text">
                  {isError
                    ? "Something went wrong"
                    : "We can't seem to find the page you're looking for"}
                  <span style={{ color: "#000000" }}> 🥺</span>.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Footer />
      </Container>
    </>
  );
};

export default ErrorPage;
